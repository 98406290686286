import { defineStore } from 'pinia'
import { ref } from 'vue'

const formatDate = (date) => {

  const day = ('0' + date.getDate()).slice(-2)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const year = date.getFullYear()

  return `${day}-${month}-${year}`
}

export const useABNCancellationStore = defineStore(
  'abnCancellationStore',
  () => {
    // Store Models
    const abn_cancellation_reason_list = [
      'Business / Enterprise has closed down',
      'Business / Enterprise has never operated',
      'Business / Enterprise has been sold',
      'Business / Enterprise is no longer carried on Australia',
      'Change in Business Structure',
      'Other'
    ]

    const abn_cancellation_day = ref(formatDate(new Date()))
    const abn_cancellation_reason = ref('Business / Enterprise has closed down')
    const other_cancellation_reason = ref('')

    const update = (abn) => {
      abn_cancellation_day.value = abn.abn_cancellation.cancellation_day
      abn_cancellation_reason.value = abn.abn_cancellation.cancellation_reason
      other_cancellation_reason.value = abn.other_cancellation_reason
    }

    return {
      abn_cancellation_reason,
      other_cancellation_reason,
      abn_cancellation_reason_list,
      abn_cancellation_day,
      update
    }
  },
  { persist: true }
)
