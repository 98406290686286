<script setup>
import CTAButton from '@components/CTAButton.vue'
import Subtitle from '@components/Subtitle.vue'

const features = [
  {
    title: 'GST Registration',
    description: [
      'Register your GST in 5 minutes. Only $79.00.'
    ],
    button: [
      {
        href: '/gst-register',
        text: 'Register my GST'
      }
    ],
  },
  {
    title: 'Business Name Registration',
    description: [
      'Register your Business Name in 5 minutes. Only $119.00 for 1 year and $199.00 for 3 years.'
    ],
    button: [
      {
        href: '/bn-register',
        text: 'Register my Business Name'
      }
    ],
  },
  {
    title: 'ABN Registration',
    description: [
      'Register your ABN in 5 minutes. Only $69.00.'
    ],
    button: [
      {
        href: '/abn-register',
        text: 'Register my ABN'
      }
    ],
  },
]
</script>

<template>
  <div class="mx-auto max-w-7xl px-0 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:text-center">
      <Subtitle>
        How to register your official ABN?
      </Subtitle>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-12 mt-8">
        <div
          v-for="feature in features"
          :key="feature.title"
          class="flex flex-col lg:text-center justify-between gap-4"
        >
          <div class="text-2xl font-semibold leading-8 text-gray-900 mb-4">
            <component :is="feature.icon" class="h-24 w-24 mx-auto mb-2 text-stone-600" aria-hidden="true"/>
            {{ feature.title }}
          </div>
          <div v-if="feature.description && feature.description.length" class="text-base leading-7 text-gray-600">
            <div v-for="(desc, index) in feature.description" :key="index">
              {{ desc }}
            </div>
          </div>
          <CTAButton
            :href="feature.button[0].href"
            :text="feature.button[0].text"
          />
        </div>
      </div>
    </div>
  </div>
</template>
