<script setup>
import CTAButton from '@components/CTAButton.vue'

const props = defineProps({
  subtitle:String,
  description: String,
  href: String,
  text: String,
})
</script>

<template>
  <div class="flex flex-col justify-between h-full gap-6">
    <dd class="text-lg font-semibold leading-7 text-gray-800 min-h-[2rem]">
      {{ props.subtitle }}
    </dd>
    <dd v-if="props.description" class="text-base leading-7 text-gray-600">
      {{ props.description }}
    </dd>
    <CTAButton
      :href="props.href"
      :text="props.text"
      class="text-lg p-4"
      size="xl"
    />
  </div>
</template>
