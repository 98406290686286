import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useBNCancellationStore = defineStore(
  'bnCancellationStore',
  () => {
    // Store Models

    const asic_key = ref('')
    const bn_name = ref('')
    const request_asic_key = ref('')

    const update = (abn) => {
      asic_key.value = abn.bn_cancellation.asic_key
      bn_name.value = abn.bn_cancellation.bn_name
      request_asic_key.value = abn.bn_cancellation.request_asic_key
    }

    return {
      bn_name,
      asic_key,
      request_asic_key,
      update
    }
  },
  { persist: true }
)
