<template>
    <div class="grid grid-cols-3 justify-items-center items-center gap-4">
      <img class="w-1/2 object-contain" src="@img/XeroLogo.png" alt="Xero"/>
      <img class="w-3/4 object-contain" src="@img/CharteredAccountantsLogo.png" alt="Chartered Accountants"/>
      <img class="w-1/2 object-contain" src="@img/TaxPractitionersBoardLogo.png" alt="Tax Practitioners Board"/>
      <img class="w-1/2 object-contain" src="@img/QuickbooksLogo.png" alt="Quickbooks"/>
      <img class="w-3/4 object-contain" src="@img/TheTaxInstituteLogo.png" alt="The Tax Institute"/>
      <img class="w-1/2 object-contain" src="@img/MyobLogo.png" alt="MYOB"/>
    </div>
</template>
