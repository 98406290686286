import { defineStore } from 'pinia'
import { ref } from 'vue'

const formatDate = (date) => {

  const day = ('0' + date.getDate()).slice(-2)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const year = date.getFullYear()

  return `${day}-${month}-${year}`
}

export const useGSTCancellationStore = defineStore(
  'gstCancellationStore',
  () => {
    // Store Models
    const gst_cancellation_reason_list = [
      'The entity has never operated on a GST registered basis',
      'The entity is stopping operating on a GST registered basis'
    ]

    const gst_cancellation_day = ref(formatDate(new Date()))
    const gst_cancellation_reason = ref(
      'The entity has never operated on a GST registered basis'
    )

    const update = (abn) => {
      gst_cancellation_day.value = abn.gst_cancellation.cancellation_day
      gst_cancellation_reason.value = abn.gst_cancellation.cancellation_reason
    }

    return {
      gst_cancellation_reason,
      gst_cancellation_reason_list,
      gst_cancellation_day,
      update
    }
  },
  { persist: true }
)
