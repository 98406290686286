<script setup>
import { ClipboardDocumentListIcon, ClipboardDocumentCheckIcon, EnvelopeIcon } from '@heroicons/vue/24/outline'
import CTAButton from '@components/CTAButton.vue'
import Subtitle from '@components/Subtitle.vue'

const features = [
  {
    icon: ClipboardDocumentListIcon,
    title: 'Fill out an online single form',
    description: [
      'Use the registration form to provide the information required to generate your ABN.',
      'No guesswork our Tax Professionals can answer your questions.',
      'Register a Business Name or/and GST (if needed) on the same form.'
    ],
  },
  {
    icon: ClipboardDocumentCheckIcon,
    title: 'We review & proceed your application',
    description: [
      'On receiving the details, your ABN application is promptly reviewed by a team of experts for any compliance issues before being processed. We double check BEFORE submission.'
    ],
  },
  {
    icon: EnvelopeIcon,
    title: 'Your registered ABN is delivered by email & sms',
    description: [
      'Your ABN will be delivered by email and SMS usually within 5 minutes and always within 2 hours.'
    ],
  },
]
</script>

<template>
  <div class="mx-auto max-w-7xl px-0 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:text-center">
      <Subtitle>
        How to register your official ABN?
      </Subtitle>
    </div>
    <div
      class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl grid gap-6"
    >
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-12 mt-8">
        <div v-for="feature in features" :key="feature.title" class="flex flex-col">
          <div class="text-2xl font-semibold leading-8 text-gray-900 mb-4">
            <component :is="feature.icon" class="h-24 w-24 mx-auto mb-2 text-indigo-600" aria-hidden="true"/>
            {{ feature.title }}
          </div>
          <div v-if="feature.description && feature.description.length" class="text-base leading-7 text-gray-600">
            <div v-for="(desc, index) in feature.description" :key="index">
              {{ desc }}
            </div>
          </div>
        </div>
      </div>
      <CTAButton
        href="/abn-register"
        text="Start my ABN Application"
        class="w-full lg:w-auto mx-auto"
        size="xl"
      />
    </div>
  </div>
</template>
